export const setPhone = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_PHONE_NUMBER",
      data,
    });
    localStorage.setItem("phoneNumber", JSON.stringify(data));
  };
};

export const setDelivery = (data) => {
  return {
    type: `SET_DELIVERY`,
    data,
  };
};

export const setPayment = (data) => {
  return {
    type: `SET_PAYMENT`,
    data,
  };
};

export const setBillingType = (data) => {
  return {
    type: `SET_BILLING_TYPE`,
    data,
  };
};

export const setShipping = (data) => {
  return async (dispatch, getState) => {
    const shippingData = getState().checkout.shippingAddress;
    const newShippingData = { ...shippingData, ...data };
    dispatch({
      type: `SET_SHIPPING`,
      data: newShippingData,
    });
    localStorage.setItem("shipping", JSON.stringify(newShippingData));
  };
};

export const setBilling = (data) => {
  return async (dispatch, getState) => {
    const billingData = getState().checkout.billingAddress;
    const newBillingData = { ...billingData, ...data };

    dispatch({
      type: `SET_BILLING`,
      data: newBillingData,
    });
    localStorage.setItem("billing", JSON.stringify(newBillingData));
  };
};
