import React from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  location,
  isLoggedIn,
  ...rest
}) => {
  if (!isLoggedIn) {
    navigate("/");
    return null;
  }
  return <Component {...rest} />;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
