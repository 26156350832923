import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import { getOrders } from "../../state/actions/orders";
import OrderDetails from "../orderDetails";

const PastOrders = (props) => {
  useEffect(() => {
    props.getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState({});

  const selectHandler = (order) => {
    setOrder(order);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      {props.orders.length ? (
        <Table hover borderless responsive>
          <thead>
            <tr>
              <th>OrderID</th>
              <th>Order Date</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            {props.orders
              .sort((a, b) => {
                if (a.orderID < b.orderID) return 1;
                if (a.orderID > b.orderID) return -1;
                return 0;
              })
              .map((order) => (
                <tr
                  onClick={() => selectHandler(order)}
                  style={{ cursor: "pointer" }}
                  key={order.orderID}
                >
                  <td>{order.orderID}</td>
                  <td>{order.createdAt.split("T")[0]}</td>
                  <td>{order.orderStatus}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-muted">
          It looks like you haven't placed any orders yet!
        </p>
      )}
      <OrderDetails
        show={showModal}
        onHide={() => setShowModal(false)}
        order={order}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: () => dispatch(getOrders()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrders);
