import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import {
  setBillingType,
  setPayment,
  setBilling,
} from "../../state/actions/checkout";

import styles from "../../styles/components/checkout/payment.module.scss";

const Payment = (props) => {
  return (
    <div className={styles.container}>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Information</h1>
        <div className={styles.infoDiv}>
          <div className={styles.info}>
            <div>{props.phone || "Contact"}</div>
            <div>
              <span onClick={() => props.btnClick(1)}>Change</span>
            </div>
          </div>
          <div className={styles.info}>
            <div>{props.shipping.address || "Ship To"}</div>
            <div>
              <span onClick={() => props.btnClick(1)}>Change</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Payment</h1>
        <p className={styles.desc}>All transactions are secure and ecrypted</p>
        <div className={styles.optionDiv}>
          <div className={styles.option}>
            <Form.Check
              className={styles.radioBtn}
              name="payment"
              type="radio"
              label="Card Payment"
              checked={props.payment === "Card"}
              onChange={() => props.setPayment("Card")}
            />
          </div>
          <div className={styles.option}>
            <Form.Check
              className={styles.radioBtn}
              name="payment"
              type="radio"
              label="Cash on Delivery"
              checked={props.payment === "Cash on Delivery"}
              onChange={() => props.setPayment("Cash on Delivery")}
            />
          </div>
          <div className={styles.option}>
            <Form.Check
              className={styles.radioBtn}
              name="payment"
              type="radio"
              label="Bank Transfer"
              checked={props.payment === "Bank Transfer"}
              onChange={() => props.setPayment("Bank Transfer")}
            />
            {props.payment === "Bank Transfer" && (
              <div className="mx-5 mt-4">
                <p className="m-0">Account Name: Blazen</p>
                <p className="m-0">Account Number: 1063 1402 6232</p>
                <p className="m-0">Bank Name: Sampath Bank</p>
                <p>Branch: Pelawatta</p>
                <p className="small">
                  Please include your Order ID and name as the reference when
                  making the bank transfer. Send us transaction proof to
                  info@blazen.lk or +94-71-281-4071
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Billing Address</h1>
        <p className={styles.desc}>
          Select the address that matches your card or payment method
        </p>
        <div className={styles.optionDiv}>
          <div className={styles.option}>
            <Form.Check
              className={styles.radioBtn}
              name="address"
              type="radio"
              label="Same as shipping address"
              checked={!props.differentBillingAddress}
              onChange={() => props.setBillingType(false)}
            />
          </div>
          <div className={styles.option}>
            <Form.Check
              className={styles.radioBtn}
              name="address"
              type="radio"
              label="Use a different billing address"
              checked={props.differentBillingAddress}
              onChange={() => props.setBillingType(true)}
            />
            {props.differentBillingAddress && (
              <div className="mt-4">
                <div className={styles.billingForm}>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="First Name"
                    value={props.billing.firstName}
                    onChange={(e) =>
                      props.setBilling({ firstName: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Last Name"
                    value={props.billing.lastName}
                    onChange={(e) =>
                      props.setBilling({ lastName: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className={`${styles.input} ${styles.inputSpan}`}
                    placeholder="Address"
                    value={props.billing.address}
                    onChange={(e) =>
                      props.setBilling({ address: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className={`${styles.input} ${styles.inputSpan}`}
                    placeholder="Apartment, Suite, Etc. (Optional)"
                    value={props.billing.apartment}
                    onChange={(e) =>
                      props.setBilling({ apartment: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="City"
                    value={props.billing.city}
                    onChange={(e) => props.setBilling({ city: e.target.value })}
                  />
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Country / Region"
                    value={props.billing.country}
                    onChange={(e) =>
                      props.setBilling({ country: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Postal Code"
                    value={props.billing.postalCode}
                    onChange={(e) =>
                      props.setBilling({ postalCode: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    phone: state.checkout.phoneNumber,
    shipping: state.checkout.shippingAddress,
    payment: state.checkout.payment,
    differentBillingAddress: state.checkout.differentBillingAddress,
    billing: state.checkout.billingAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPayment: (data) => dispatch(setPayment(data)),
    setBillingType: (data) => dispatch(setBillingType(data)),
    setBilling: (data) => dispatch(setBilling(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
