import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ReactStars from "react-stars";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";

import userPlaceholder from "../../images/landing/user-placeholder.png";
import styles from "../../styles/components/admin/reviewModal.module.scss";

const ReviewModal = (props) => {
  const [editStatus, setEditStatus] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(props.review.status);

  useEffect(() => {
    setReviewStatus(props.review.status);
  }, [props.review]);

  const handleSave = async () => {
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/review/${props.review._id}`,
        {
          status: reviewStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${props.auth.token}`,
          },
        }
      );
      setEditStatus(false);
    } catch (e) {
      toast.error("There was an error updating review status!");
      console.log(`Error: ${e.response.data}`);
    }
  };

  return (
    <Modal show={props.show} onHide={props.close} centered>
      <Modal.Header className="modal-header" closeButton />
      <Modal.Body>
        <div className="px-3">
          <div className={styles.userReview}>
            <div className={styles.userImage}>
              <img
                src={props.review.image || userPlaceholder}
                alt="User Img"
                height="100%"
                width="100%"
              />
            </div>
            <div>
              <h5 className={styles.userName}>{props.review.name}</h5>
            </div>
            <div style={{ marginTop: "-15px", textAlign: "center" }}>
              <ReactStars
                count={props.review.rating}
                size={24}
                edit={false}
                value={props.review.rating}
              />
            </div>
            <p className={styles.reviewDesc}>{props.review.review}</p>
          </div>
          <div className="mt-3">
            <strong>Email : </strong>
            {props.review.email}
          </div>
          <div className="mt-3">
            <strong>Status : </strong>
            {editStatus ? (
              <select
                value={reviewStatus}
                onChange={(e) => setReviewStatus(e.target.value)}
              >
                <option value="Pending">Pending</option>
                <option value="Approved" className="text-success">
                  Approved
                </option>
                <option value="Rejected" className="text-danger">
                  Rejected
                </option>
              </select>
            ) : (
              reviewStatus
            )}
          </div>
          <Button
            className="mt-3 px-3"
            onClick={() => (editStatus ? handleSave() : setEditStatus(true))}
          >
            {editStatus ? "Save Status" : "Edit Status"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ReviewModal);
