import React from "react";
import { connect } from "react-redux";
import { Container, Row, ListGroup, Col, Tab } from "react-bootstrap";
import PastOrders from "./pastOrders";

import styles from "../../styles/components/user/userPg.module.scss";

const UserPg = ({ user, isLoggedIn }) => {
  return (
    <Container className="mt-5 mb-5">
      <h1 className={`${styles.titleMain} display-2 mb-5`}>
        User <span>Account</span>
      </h1>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
        <Row className="mt-5 gx-5">
          <Col sm={4}>
            <ListGroup>
              <ListGroup.Item action href="#link1">
                Personal Information
              </ListGroup.Item>
              <ListGroup.Item action href="#link2">
                Past Orders
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#link1">
                <div className={styles.info}>
                  <div className={styles.item}>
                    <p className="text-muted small me-5">Name</p>
                    <p> {user?.name}</p>
                  </div>
                  <div className={styles.item}>
                    <p className="text-muted small me-5">Email</p>
                    <p> {user?.email}</p>
                  </div>
                  <div className={styles.item}>
                    <p className="text-muted small me-5">Phone Number</p>
                    <p> {user?.phoneNumber}</p>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="#link2">
                <PastOrders />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.auth?.user,
  };
};

export default connect(mapStateToProps)(UserPg);
