import React, { useEffect } from "react";
import Img from "gatsby-image/withIEPolyfill";
import { toast } from "react-toastify";
import { FiMinus, FiPlus } from "react-icons/fi";
import { connect } from "react-redux";
import { removeItem, incQty, decrQty } from "../../state/actions/cart";
import { getProducts } from "../../state/actions/products";
import { Link } from "gatsby";
import confirmAlert from "../../utils/confirmAlert";

import styles from "../../styles/components/checkout/cart.module.scss";

const Cart = (props) => {
  const removeItemHandler = (id) => {
    confirmAlert({
      title: "Remove Item",
      message: "Are you sure you want to remove this item from your cart?",
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: () => {
            props.removeItem(id);
            toast.error(`Item removed from cart!`);
          },
        },
      ],
    });
  };

  useEffect(() => {
    props.getProducts();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={`${styles.titleMain} h1`}>
        Your Cart <span>[{props.cart.length}]</span>
      </h1>

      {!props.cart.length && (
        <div className=" mb-5">
          <p className={`small`}>
            You have no items in your cart!
            <span className={styles.link}>
              <Link to="/shop">
                {" "}
                <strong>Start Shopping</strong>
              </Link>
            </span>
          </p>
        </div>
      )}
      <div className={styles.itemList}>
        {props.cart.map((item) => (
          <div className={styles.item} key={item.id}>
            <div className={styles.itemImg}>
              <Img
                fixed={item.img}
                alt={item.name}
                style={{ width: "100%", height: "100%" }}
                objectFit="contain"
                objectPosition="center"
              />
            </div>
            <div className={styles.itemInfo}>
              <h1 className={`${styles.itemName} h1`}>{item.name}</h1>
              <p className="mb-0">{item.flavor}</p>
            </div>
            <div className={styles.itemControls}>
              <h3>Quantity</h3>
              <div className={styles.quantityDiv}>
                <div
                  className={styles.quantityBtn}
                  onClick={() => props.decrQty(item.id)}
                >
                  <FiMinus />
                </div>
                <span className={styles.quantity}>{item.qty}</span>
                <div
                  className={styles.quantityBtn}
                  onClick={() => props.incQty(item.id)}
                >
                  <FiPlus />
                </div>
              </div>
              <h5 onClick={() => removeItemHandler(item.id)}>Remove item</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
    removeItem: (id) => dispatch(removeItem(id)),
    incQty: (id) => dispatch(incQty(id)),
    decrQty: (id) => dispatch(decrQty(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
