import React from "react";
import { Button, CloseButton } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

// Styles
import styles from "../styles/utils/confirmAlert.module.scss";

export default ({ title, message, buttons }) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={styles.main}>
          <div className={styles.close}>
            <CloseButton onClick={onClose} className={styles.close} />
          </div>
          <h1 className="mt-3">{title}</h1>
          <p>{message}</p>
          {buttons.map((button) => (
            <Button
              key={button.label}
              onClick={async () => {
                await button.onClick();
                onClose();
              }}
              size="lg"
              variant={button.label === "Yes" ? "primary" : "light2"}
              className="m-2"
            >
              {button.label}
            </Button>
          ))}
        </div>
      );
    },
  });
};
