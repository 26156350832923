import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { graphql, useStaticQuery } from "gatsby";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";
import convertNumber from "../utils/convertNumber";

const OrderDetails = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSupplement {
        edges {
          node {
            contentful_id
            name
            flavor
          }
        }
      }
    }
  `);

  const productsFromContentful = data.allContentfulSupplement.edges.map(
    (edge) => {
      return {
        id: edge.node.contentful_id,
        name: edge.node.name,
        flavor: edge.node.flavor,
      };
    }
  );

  const [editStatus, setEditStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    setOrderStatus(props.order.orderStatus);
  }, [props.order]);

  const handleSave = async () => {
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/orders/${props.order._id}`,
        {
          orderStatus: orderStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${props.auth.token}`,
          },
        }
      );
      setEditStatus(false);
    } catch (e) {
      toast.error("There was an error updating order status!");
      console.log(`Error: ${e.response.data}`);
    }
  };

  console.log(props.order);

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="py-3">
            <Col>
              <strong>Order ID</strong>
            </Col>
            <Col>{props.order.orderID}</Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Order Date</strong>
            </Col>
            <Col>{props.order?.createdAt?.split("T")[0]}</Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Order Status</strong>
            </Col>
            <Col>
              {props.admin && editStatus ? (
                <Form.Select
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <option value="Awaiting Payment">Awaiting Payment</option>
                  <option value="Processing">Processing</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Returned">Returned</option>
                  <option value="Refunded">Refunded</option>
                </Form.Select>
              ) : (
                orderStatus
              )}

              {props.admin && (
                <div>
                  <Button
                    className="mt-2"
                    variant="secondary"
                    onClick={() =>
                      editStatus ? handleSave() : setEditStatus(true)
                    }
                  >
                    {editStatus ? `Save` : `Edit`}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Phone</strong>
            </Col>
            <Col>{props.order.phoneNumber}</Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Payment Method</strong>
            </Col>
            <Col>{props.order.paymentMethod}</Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Delivery</strong>
            </Col>
            <Col>{props.order.deliveryOption?.deliveryType}</Col>
          </Row>
          <Row className="py-3">
            <Col>
              <strong>Shipping Address</strong>
            </Col>
            <Col>
              <div>{`${props.order.shippingAddress?.firstName} ${props.order.shippingAddress?.lastName}`}</div>
              <div>{props.order.shippingAddress?.address}</div>
              <div>{props.order.shippingAddress?.apartment}</div>
              <div>{props.order.shippingAddress?.city}</div>
              <div>{props.order.shippingAddress?.country}</div>
              <div>{props.order.shippingAddress?.postalCode}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Items</strong>
            </Col>
            <Col>
              {props.order.products?.map((product) => {
                const details = productsFromContentful.find(
                  (item) => item.id === product.productID
                );

                return (
                  <div key={details.id} className="py-2">
                    <h4>{details.name}</h4>
                    <h5>{details.flavor}</h5>
                    <h5>Qty: {product.quantity}</h5>
                    <h5>Price: LKR {convertNumber(product.price)}</h5>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OrderDetails);
