import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { connect } from "react-redux";
import { Container, Table, Button } from "react-bootstrap";
import axios from "axios";
import convertNumber from "../../utils/convertNumber";
import {
  getProducts,
  editPrice,
  editStock,
} from "../../state/actions/products";
import { toast } from "react-toastify";

const ProductList = ({ products, getProducts, editPrice, editStock, auth }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getProducts();
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allContentfulSupplement {
        edges {
          node {
            contentful_id
            name
            flavor
            productImages {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const productsFromContentful = data.allContentfulSupplement.edges.map(
    (edge) => {
      return {
        id: edge.node.contentful_id,
        name: edge.node.name,
        flavor: edge.node.flavor,
        image: edge.node.productImages[0].file.url,
      };
    }
  );

  const [edit, setEdit] = useState([]);

  const handleEdit = (productID) => {
    setEdit([...edit, productID]);
  };

  // Save product price and stock
  const handleSave = async (productID) => {
    try {
      const product = products.find((prod) => prod.productID === productID);
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/product/${productID}`,
        {
          stock: product.stock,
          price: product.price,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const newEdit = edit.filter((prodID) => prodID !== productID);
      setEdit(newEdit);
    } catch (e) {
      toast.error("There was an error saving product price and stock!");
      console.log(`Error: ${e.response.data.message}`);
    }
  };

  return (
    <Container>
      <Table striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Product</th>
            <th>Flavor</th>
            <th>Price</th>
            <th>Stock</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {productsFromContentful.length &&
            productsFromContentful
              .sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                  return -1;
                } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
                  return 1;
                }
                return;
              })
              .map((item, idx) => {
                // Get item stock and price
                const product = products.find(
                  (product) => item.id === product.productID
                );

                if (!product) return null;

                return (
                  <tr key={product.productID}>
                    <td className="align-middle">{idx + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={item.image}
                        style={{ width: "100%", maxWidth: "150px" }}
                      />
                    </td>
                    <td className="align-middle">{item.name}</td>
                    <td className="align-middle">{item.flavor}</td>
                    <td className="align-middle">
                      {edit.includes(product.productID) ? (
                        <input
                          type="number"
                          value={product.price}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              toast.error("Price cannot be negative!");
                              return;
                            }
                            editPrice({
                              productID: product.productID,
                              price: Number(e.target.value),
                            });
                          }}
                        />
                      ) : (
                        `LKR ${convertNumber(product.price)}`
                      )}
                    </td>
                    <td className="align-middle">
                      {edit.includes(product.productID) ? (
                        <input
                          type="number"
                          value={product.stock}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              toast.error("Stock cannot be negative!");
                              return;
                            }
                            editStock({
                              productID: product.productID,
                              stock: Number(e.target.value),
                            });
                          }}
                        />
                      ) : (
                        product.stock
                      )}
                    </td>
                    <td className="text-center align-middle">
                      <Button
                        variant="secondary"
                        onClick={() =>
                          edit.includes(product.productID)
                            ? handleSave(product.productID)
                            : handleEdit(product.productID)
                        }
                      >
                        {edit.includes(product.productID) ? `Save` : `Edit`}
                      </Button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
    editPrice: (data) => dispatch(editPrice(data)),
    editStock: (data) => dispatch(editStock(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
