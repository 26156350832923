import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { toast } from "react-toastify";
import ReactStars from "react-stars";
import ReviewModal from "./reviewModal";

const ReviewList = (props) => {
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState({});
  const [showModal, setShowModal] = useState(false);

  const selectHandler = (review) => {
    setSelectedReview(review);
    setShowModal(true);
  };

  const getReviews = async () => {
    try {
      const reviewsFromDB = await axios.get(
        `${process.env.GATSBY_API_CALL}/all-reviews`,
        {
          headers: { Authorization: `Bearer ${props.auth.token}` },
        }
      );
      setReviews(reviewsFromDB.data);
    } catch (e) {
      toast.error("There was an error fetching reviews!");
      console.log(`Error: ${e}`);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const hideModal = () => {
    getReviews();
    setShowModal(false);
  };

  return (
    <>
      {reviews.length > 0 ? (
        <Table responsive borderless hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Rating</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {reviews
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((review) => {
                return (
                  <tr
                    key={review._id}
                    onClick={() => selectHandler(review)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{review.name}</td>
                    <td>{review.email}</td>
                    <td>
                      <ReactStars
                        count={5}
                        size={12}
                        edit={false}
                        value={review.rating}
                      />
                    </td>
                    <td>{review.status}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <p>No reviews have been submitted yet!</p>
      )}
      <ReviewModal
        show={showModal}
        review={selectedReview}
        close={() => hideModal()}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ReviewList);
