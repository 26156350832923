import React from "react";
import { connect } from "react-redux";
import { setPhone, setShipping } from "../../state/actions/checkout";

import styles from "../../styles/components/checkout/information.module.scss";

const Information = (props) => {
  return (
    <div>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Contact Information</h1>

        <input
          type="tel"
          className={`${styles.input} ${styles.email}`}
          placeholder="Phone Number"
          value={props.phone}
          onChange={(e) => props.setPhone(e.target.value)}
        />
      </div>
      <div className={styles.shippingInfo}>
        <h1 className={`${styles.titleMain} h1`}>Shipping Address</h1>
        <div className={styles.shippingForm}>
          <input
            type="text"
            className={styles.input}
            placeholder="First Name"
            value={props.shipping.firstName}
            onChange={(e) => props.setShipping({ firstName: e.target.value })}
          />
          <input
            type="text"
            className={styles.input}
            placeholder="Last Name"
            value={props.shipping.lastName}
            onChange={(e) => props.setShipping({ lastName: e.target.value })}
          />
          <input
            type="text"
            className={`${styles.input} ${styles.inputSpan}`}
            placeholder="Address"
            value={props.shipping.address}
            onChange={(e) => props.setShipping({ address: e.target.value })}
          />
          <input
            type="text"
            className={`${styles.input} ${styles.inputSpan}`}
            placeholder="Apartment, Suite, Etc. (Optional)"
            value={props.shipping.apartment}
            onChange={(e) => props.setShipping({ apartment: e.target.value })}
          />
          <input
            type="text"
            className={styles.input}
            placeholder="City"
            value={props.shipping.city}
            onChange={(e) => props.setShipping({ city: e.target.value })}
          />
          <input
            type="text"
            className={styles.input}
            placeholder="Country / Region"
            value={props.shipping.country}
            onChange={(e) => props.setShipping({ country: e.target.value })}
          />
          <input
            type="text"
            className={styles.input}
            placeholder="Postal Code"
            value={props.shipping.postalCode}
            onChange={(e) => props.setShipping({ postalCode: e.target.value })}
          />
        </div>
      </div>
      <div className={styles.terms}>
        *I agree to receive recurring automated marketing text messages (e.g.
        cart reminders) at the phone number provided. Consent is not a condition
        to purchase. Message &amp; data rates may apply. Message frequency
        varies.
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    phone: state.checkout.phoneNumber,
    shipping: state.checkout.shippingAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPhone: (data) => dispatch(setPhone(data)),
    setShipping: (data) => dispatch(setShipping(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
