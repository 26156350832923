import React from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { setDelivery } from "../../state/actions/checkout";
import { GrLocation } from "react-icons/gr";

import styles from "../../styles/components/checkout/shipping.module.scss";

const Shipping = (props) => {
  const deliveryOptions = [
    {
      deliveryType: "Standard Delivery",
      deliveryPrice: 500,
    },
    {
      deliveryType: "Pickup",
      deliveryPrice: 0,
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Information</h1>
        <div className={styles.infoDiv}>
          <div className={styles.info}>
            <div>{props.phone || "Contact"}</div>
            <div>
              <span onClick={() => props.btnClick(1)}>Change</span>
            </div>
          </div>
          <div className={styles.info}>
            <div>{props.shipping.address || "Ship To"}</div>
            <div>
              <span onClick={() => props.btnClick(1)}>Change</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className={`${styles.titleMain} h1`}>Delivery Options</h1>
        <div>
          {deliveryOptions.map((option, idx) => (
            <div className={styles.deliveryOption} key={idx}>
              <Form.Check
                className={styles.radioBtn}
                name="delivery"
                type="radio"
                label={`${option.deliveryType}`}
                checked={props.delivery.deliveryType === option.deliveryType}
                onChange={() => props.setDelivery(option)}
              />
              <span>
                {option.deliveryPrice ? `LKR ${option.deliveryPrice}` : "Free"}
              </span>
            </div>
          ))}

          {props.delivery.deliveryType === "Pickup" && (
            <p className="small mt-3">
              Pickup from{" "}
              <a
                className={styles.link}
                target="_blank"
                href="https://www.google.com/maps/place/Lifetime+Fitness/@6.877197,79.9328003,17z/data=!3m1!4b1!4m5!3m4!1s0x3ae250966951753f:0x237abed848821c37!8m2!3d6.8771971!4d79.9350366"
              >
                <GrLocation className="me-2" />
                Lifetime Fitness Gym, Thalawathugoda
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    phone: state.checkout.phoneNumber,
    shipping: state.checkout.shippingAddress,
    delivery: state.checkout.deliveryOption,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelivery: (data) => dispatch(setDelivery(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
