import axios from "axios";
import { toast } from "react-toastify";

export const getOrders = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token;
    try {
      const ordersFromDB = await axios.get(
        `${process.env.GATSBY_API_CALL}/orders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(setOrders(ordersFromDB.data));
    } catch (e) {
      toast.error(`Could not retrieve orders!`);
      console.log(`Error: ${e.response.data.message}`);
    }
  };
};

const setOrders = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_ORDERS",
      data: data,
    });
  };
};
