import React from "react";
import { Row, Col, Tab, Container, ListGroup } from "react-bootstrap";
import ProductList from "./ProductList";
import OrderList from "./orderList";
import ReviewList from "./reviewList";

import styles from "../../styles/components/admin/adminPg.module.scss";

const AdminPg = () => {
  return (
    <Container className="mt-5 mb-5">
      <h1 className={`${styles.titleMain} display-2 mb-5`}>
        Admin <span>Panel</span>
      </h1>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#orders">
        <Row className="mt-5">
          <ListGroup className="list-group-horizontal p-0">
            <Col className="p-0">
              <ListGroup.Item action href="#orders">
                Orders
              </ListGroup.Item>
            </Col>
            <Col className="p-0">
              <ListGroup.Item action href="#items">
                Items
              </ListGroup.Item>
            </Col>
            <Col className="p-0">
              <ListGroup.Item action href="#reviews">
                Reviews
              </ListGroup.Item>
            </Col>
          </ListGroup>
        </Row>
        <Tab.Content className="mt-5">
          <Tab.Pane eventKey="#orders">
            <OrderList />
          </Tab.Pane>

          <Tab.Pane eventKey="#items">
            <ProductList />
          </Tab.Pane>

          <Tab.Pane eventKey="#reviews">
            <ReviewList />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default AdminPg;
