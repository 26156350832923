import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import PrivateRoute from "../components/privateRoute";
import AdminRoute from "../components/adminRoute";
import UserPg from "../components/user/userPg";
import AdminPg from "../components/admin/adminPg";
import CheckoutPg from "../components/checkout/checkoutPg";
import Landing from "../components/landing/landing";
import SEO from "../components/seo";

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/user" component={UserPg} />
      <AdminRoute path="/app/admin" component={AdminPg} />
      <CheckoutPg path="/app/checkout" />
      <Landing path="/" />
    </Router>
  </Layout>
);

export default App;
