import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import axios from "axios";
import OrderDetails from "../orderDetails";

const OrderList = (props) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showModal, setShowModal] = useState(false);

  const selectHandler = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const getOrders = () => {
    if (!props.auth?.token) {
      return;
    }
    axios
      .get(`${process.env.GATSBY_API_CALL}/all-orders`, {
        headers: { Authorization: `Bearer ${props.auth.token}` },
      })
      .then((res) => {
        setOrders([...res.data]);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const hideModal = () => {
    getOrders();
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {orders.length > 0 ? (
        <Table responsive borderless hover>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer ID</th>
              <th>Order Date</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.length &&
              orders
                .sort((a, b) => {
                  if (a.orderID < b.orderID) return 1;
                  if (a.orderID > b.orderID) return -1;
                  return 0;
                })
                .map((order) => {
                  return (
                    <tr
                      onClick={() => selectHandler(order)}
                      key={order.orderID}
                      style={{ cursor: "pointer" }} 
                      className="small"
                    >
                      <td>{order.orderID}</td>
                      <td>{order.customerID}</td>
                      <td>{order.createdAt.split("T")[0]}</td>
                      <td>{order.orderStatus}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      ) : (
        <p className="text-muted">No orders have been placed yet!</p>
      )}
      <OrderDetails
        show={showModal}
        order={selectedOrder}
        admin={true}
        onHide={() => hideModal()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OrderList);
