import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Cart from "./cart";
import Information from "./information";
import Shipping from "./shipping";
import Payment from "./payment";
import Success from "./success";
import { Container, Button } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import { FaSpinner } from "react-icons/fa";
import { clearCart } from "../../state/actions/cart";
import LoginModal from "../loginModal";
import LoginModalMobile from "../loginModalMobile";
import useWindowDimensions from "../../utils/useWindowDimensions";

import styles from "../../styles/components/checkout/checkoutPg.module.scss";

const CheckoutPg = (props) => {
  const { width } = useWindowDimensions();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  // Login
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginModalMobile, setShowLoginModalMobile] = useState(false);

  // Order
  const [order, setOrder] = useState({});

  useEffect(() => {
    setPage(0);
  }, [props.isLoggedIn]);

  const handleShowLoginModal = () => {
    if (!props.isLoggedIn) {
      if (width > 768) {
        setShowLoginModal(true);
      } else {
        setShowLoginModalMobile(true);
      }
    }
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleShowLoginModal();
  }, [props.isLoggedIn]);

  const pageHandler = (pg) => {
    if (!props.isLoggedIn) {
      setPage(0);
      handleShowLoginModal();
      toast.error("Please login to continue");
      return;
    } else if (!props.cart.length && pg !== -1) {
      setPage(0);
      toast.error("Your cart is empty");
      return;
    } else {
      switch (pg) {
        case -1:
          navigate("/shop");
          break;
        case 0:
          setPage(0);
          break;
        case 1:
          setPage(1);
          break;
        case 2:
          setPage(2);
          break;
        case 3:
          setPage(3);
          break;
        default:
          setPage(0);
          break;
      }
    }
  };

  const cartSubTotal = () => {
    return props.cart.reduce((acc, item) => item.price * item.qty + acc, 0);
  };

  const checkStock = async () => {
    try {
      let inStock = true;
      const fromDB = await axios.get(`${process.env.GATSBY_API_CALL}/products`);
      const products = fromDB.data;
      props.cart.forEach((item) => {
        const stock = products.find((prod) => prod.productID === item.id).stock;
        if (item.qty > stock) {
          toast.error(
            stock
              ? `Sorry. We only have ${stock} of ${item.name} in stock!`
              : `Sorry. ${item.name} is out of stock!`
          );
          inStock = false;
        }
      });

      return inStock;
    } catch (e) {
      toast.error(`There was an error with validating the stock. Error: ${e}`);
      return false;
    }
  };

  // Save order to the database
  const saveOrder = async () => {
    try {
      const orderData = {
        shippingAddress: { ...props.checkout.shippingAddress },
        billingAddress: props.checkout.differentBillingAddress
          ? { ...props.checkout.billingAddress }
          : { ...props.checkout.shippingAddress },
        phoneNumber: props.checkout.phoneNumber,
        paymentMethod: props.checkout.payment,
        products: props.cart.map((item) => {
          return {
            productID: item.id,
            quantity: item.qty,
            price: item.price,
          };
        }),
        deliveryOption: { ...props.checkout.deliveryOption },
      };
      const order = await axios.post(
        `${process.env.GATSBY_API_CALL}/orders`,
        orderData,
        {
          headers: {
            Authorization: `Bearer ${props.auth.token}`,
          },
        }
      );
      setOrder(order.data);
      setPage(-1);
      props.clearCart();
    } catch (e) {
      toast.error(
        `There was an error processing the order. Please try again later!`
      );
      console.log(`Error: ${e}`);
      setLoading(false);
    }
  };

  const purchaseHandler = async () => {
    setLoading(true);

    // Check if contact details are filled
    if (
      !(
        props.checkout.phoneNumber &&
        props.checkout.shippingAddress.firstName &&
        props.checkout.shippingAddress.lastName &&
        props.checkout.shippingAddress.address &&
        props.checkout.shippingAddress.city &&
        props.checkout.shippingAddress.country &&
        props.checkout.shippingAddress.postalCode
      )
    ) {
      toast.error("Please fill the required shipping and contact information!");
      setLoading(false);
      return;
    }

    // Check if billing address is different and filled
    if (
      props.checkout.differentBillingAddress &&
      !(
        props.checkout.billingAddress.firstName &&
        props.checkout.billingAddress.lastName &&
        props.checkout.billingAddress.address &&
        props.checkout.billingAddress.city &&
        props.checkout.billingAddress.country &&
        props.checkout.billingAddress.postalCode
      )
    ) {
      toast.error("Please enter a billing address!");
      setLoading(false);
      return;
    }

    // Check if items are in stock
    if (!(await checkStock())) {
      setLoading(false);
      return;
    }

    if (props.checkout.payment === "Card") {
      // Payment variables for payhere
      const payment = {
        sandbox: false,
        merchant_id: `${process.env.GATSBY_PAYHERE_MERCHANT_ID}`,
        return_url: undefined,
        cancel_url: undefined,
        notify_url: `${process.env.GATSBY_API_CALL}/payment`,
        order_id: Date.now() + Math.floor(Math.random() * 1000 + 1),
        items: "Supplement Order",
        amount:
          props.cart.reduce((acc, item) => item.price * item.qty + acc, 0) +
          props.checkout.deliveryOption.deliveryPrice,
        currency: "LKR",
        first_name: props.checkout.differentBillingAddress
          ? props.checkout.billingAddress.firstName
          : props.checkout.shippingAddress.firstName,
        last_name: props.checkout.differentBillingAddress
          ? props.checkout.billingAddress.lastName
          : props.checkout.shippingAddress.lastName,
        email: props.auth.user.email,
        phone: props.checkout.phoneNumber,
        address: props.checkout.differentBillingAddress
          ? props.checkout.billingAddress.address
          : props.checkout.shippingAddress.address,
        city: props.checkout.differentBillingAddress
          ? props.checkout.billingAddress.city
          : props.checkout.shippingAddress.city,
        country: props.checkout.differentBillingAddress
          ? props.checkout.billingAddress.country
          : props.checkout.shippingAddress.country,
      };

      // Open payhere modal
      window.payhere.startPayment(payment);
    } else {
      saveOrder();
    }
  };

  // Payhere Modal methods
  // Called when user completed the payment. It can be a successful payment or failure
  window.payhere.onCompleted = async function onCompleted(order_id) {
    //Note: validate the payment and show success or failure page to the customer

    try {
      // Get payment details from DB
      const paymentFromDB = await axios.get(
        `${process.env.GATSBY_API_CALL}/payment/${order_id}`
      );

      if (!paymentFromDB) {
        toast.error(
          "There was an error processing the payment. Please try again later!"
        );
        setLoading(false);
        return;
      }

      // Save order to the database
      saveOrder();
    } catch (e) {
      toast.error(`There was an error processing the payment. ${e}`);
      setLoading(false);
    }
  };

  // Called when user closes the payment without completing
  window.payhere.onDismissed = function onDismissed() {
    setLoading(false);
  };

  // Called when error happens when initializing payment such as invalid parameters
  window.payhere.onError = function onError(error) {
    toast.error(`There was an error with the payment. ${error}`);
    console.log("Error:" + error);
    setLoading(false);
  };

  const getContent = (page) => {
    switch (page) {
      case 0:
        return <Cart />;
      case 1:
        return <Information btnClick={pageHandler} />;
      case 2:
        return <Shipping btnClick={pageHandler} />;
      case 3:
        return <Payment btnClick={pageHandler} />;
      default:
        return <Cart />;
    }
  };

  return (
    <Container className={styles.container}>
      <LoginModal
        show={!props.isLoggedIn && showLoginModal}
        hide={() => setShowLoginModal(false)}
      />
      <LoginModalMobile
        show={!props.isLoggedIn && showLoginModalMobile}
        hide={() => setShowLoginModalMobile(false)}
      />

      {page === -1 ? (
        <Success order={order} />
      ) : (
        <div>
          <div>
            <h1 className={`${styles.titleMain} mb-2 mb-lg-4`}>
              Check<span>out</span>
            </h1>
            <div className={styles.breadcrumbs}>
              <Button
                variant=""
                className={
                  page === 0
                    ? `${styles.breadcrumb} ${styles.active}`
                    : `${styles.breadcrumb}`
                }
                onClick={() => pageHandler(0)}
              >
                <span>Cart</span>
              </Button>
              <Button
                variant=""
                className={
                  page === 1
                    ? `${styles.breadcrumb} ${styles.active}`
                    : `${styles.breadcrumb}`
                }
                onClick={() => pageHandler(1)}
              >
                <span>Information</span>
              </Button>
              <Button
                variant=""
                className={
                  page === 2
                    ? `${styles.breadcrumb} ${styles.active}`
                    : `${styles.breadcrumb}`
                }
                onClick={() => pageHandler(2)}
              >
                <span>Delivery</span>
              </Button>
              <Button
                variant=""
                className={
                  page === 3
                    ? `${styles.breadcrumb} ${styles.active}`
                    : `${styles.breadcrumb}`
                }
                onClick={() => pageHandler(3)}
                role="button"
              >
                <span>Payment</span>
              </Button>
            </div>
            <div>{getContent(page)}</div>
          </div>

          <div className={styles.summaryDiv}>
            <div className={styles.summaryRow}>
              <h4>Subtotal</h4>
              <h4 className={styles.subTotal}>LKR {cartSubTotal()}</h4>
            </div>
            <div className={styles.summaryRow}>
              <h4>Delivery</h4>
              <h4 className={styles.ghostedValue}>
                LKR {props.checkout.deliveryOption.deliveryPrice}
              </h4>
            </div>
            <div className={styles.summaryRow}>
              <h4>Cart Total</h4>
              <h4 className={styles.summaryTotal}>
                LKR{" "}
                {cartSubTotal() + props.checkout.deliveryOption.deliveryPrice}
              </h4>
            </div>
          </div>

          <div className={styles.btnDiv}>
            <Button
              className={styles.backBtn}
              variant=""
              onClick={() => pageHandler(page - 1)}
            >
              <IoIosArrowBack /> {!props.cart.length ? "Back" : "Previous"}
            </Button>
            {page !== 3 ? (
              <Button
                className={styles.continueBtn}
                onClick={() => pageHandler(page + 1)}
              >
                Next
              </Button>
            ) : (
              <Button
                disabled={loading}
                className={styles.continueBtn}
                onClick={() => purchaseHandler()}
              >
                {loading ? <FaSpinner className="spinner" /> : "Purchase"}
              </Button>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    auth: state.auth.auth,
    products: state.products.products,
    cart: state.cart.cart,
    checkout: state.checkout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(clearCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPg);
