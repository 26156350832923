import React from "react";
import { Link } from "gatsby";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "react-bootstrap";

import styles from "../../styles/components/checkout/success.module.scss";

const Success = (props) => {
  return (
    <div className={styles.content}>
      <h1>
        <AiOutlineCheckCircle className={styles.checkIcon} />
      </h1>
      <h1 className={`${styles.titleMain} display-2`}>
        Order <span>Confirmed!</span>
      </h1>
      <h4>OrderID: {props.order.orderID}</h4>
      <p>
        Your order has been placed successfully. You will receive an email
        confirmation shortly.
      </p>
      <p>Thank you for shopping with Blazen Supplements.</p>
      <Link to="/shop">
        <Button variant="primary" size="lg" className="mt-3">Continue Shopping</Button>
      </Link>
    </div>
  );
};

export default Success;
