import React from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";

const AdminRoute = ({ component: Component, isLoggedIn, isAdmin, ...rest }) => {
  if (!isLoggedIn || !isAdmin) {
    navigate("/");
    return null;
  }

  return <Component {...rest} />;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isAdmin: state.auth.auth?.user.admin,
  };
};

export default connect(mapStateToProps)(AdminRoute);
